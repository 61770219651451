import { Button } from "@vseth/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchOrder,
    Order,
    selectOrderItems,
    selectOrderTotal,
} from "../features/payment/paymentSlice";
import { getFrancs } from "../util/proto";
import { GatewayButtons } from "./GatewayButtons";
import { OrderItemsTable } from "./OrderItemsTable";

interface Props {
    order: Order;
}

// Checkout renders the order together with buttons to pay it
export const Checkout: React.FC<Props> = ({ order }) => {
    const orderItems = useSelector(selectOrderItems);
    const orderTotal = useSelector(selectOrderTotal);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div>
            <section className="mb-5">
                <h2>
                    {t("Checkout.orderWithNumber", { reference: order.number })}
                </h2>
                <div>
                    <OrderItemsTable orderItems={orderItems} />
                    <div
                        style={{
                            width: "100%",
                            maxWidth: "14rem",
                            display: "block",
                        }}
                    >
                        <div style={{ float: "left" }}>
                            <b>{t("Checkout.total")}</b>
                        </div>
                        <div style={{ float: "right" }}>
                            <b>CHF {getFrancs(orderTotal)}</b>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-3">
                <h3>{t("Checkout.selectPaymentOptions")}</h3>
                <GatewayButtons />
            </section>
            <section className="mb-5">
                <p>{t("Checkout.alreadyPaidReload")}</p>
                <Button
                    className="m-2"
                    onClick={(_) => dispatch(fetchOrder(order.name))}
                >
                    {t("Checkout.updateOrderButton")}
                </Button>
            </section>
        </div>
    );
};
