import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "@vseth/auth";

const initialState: AuthState = {
    isAuthenticated: false,
};

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, { payload }: PayloadAction<AuthState>) {
            return payload;
        },
    },
});

export const { setAuthState } = auth.actions;

export default auth.reducer;

// selectors
type AuthSliceRoot = {
    auth: ReturnType<typeof auth.reducer>;
};

export const selectTokens = (state: AuthSliceRoot) => state.auth.tokens;
export const selectUserInfo = (state: AuthSliceRoot) => state.auth.userInfo;
export const selectIsAuthenticated = (state: AuthSliceRoot) =>
    state.auth.isAuthenticated;

export const selectAccessToken = createSelector([selectTokens], (tokens) => {
    if (tokens) {
        return tokens.token;
    }
    return undefined;
});

const clientId = "payment-checkout";

export const selectUserRoles = createSelector([selectUserInfo], (userInfo) => {
    if (
        userInfo &&
        userInfo.resource_access &&
        userInfo.resource_access[clientId]
    ) {
        return userInfo.resource_access[clientId].roles;
    }
    return [] as string[];
});
