import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";
import { PaymentPromiseClient } from "../proto/vseth/sip/payment/payment_grpc_web_pb";

/**
 * Construct the redux store used in the application.
 */

const client = new PaymentPromiseClient(
  "https://payment.web.api.staging-sip.ethz.ch",
  {},
  {}
);

const customizedMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: client,
  },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

if (process.env.NOVE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer.ts", () => {
    const newRootReducer = require("./rootReducer.ts").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<
  void,
  RootState,
  PaymentPromiseClient,
  Action<string>
>;

export default store;
