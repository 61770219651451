import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { OrderItem } from "../features/payment/paymentSlice";
import { Money } from "../proto/vseth/type/money_pb";
import { getFrancs } from "../util/proto";

interface Props {
    orderItems: OrderItem[];
}

const orderItemPriceFormatter = (
    cell: string,
    row: OrderItem,
    rowIndex: number
) => getFrancs(row.itemAmount);

const sortPrice = (
    a: Money.AsObject,
    b: Money.AsObject,
    order: string,
    dataField: string,
    aRow: OrderItem,
    bRow: OrderItem
): number => {
    if (!aRow.itemAmount || !bRow.itemAmount) {
        return 0;
    }
    console.log(aRow.itemAmount.cents - bRow.itemAmount.cents);
    if (order === "asc") {
        return bRow.itemAmount.cents - aRow.itemAmount.cents;
    }
    return aRow.itemAmount.cents - bRow.itemAmount.cents;
};

export const OrderItemsTable: React.FC<Props> = ({ orderItems }) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: "displayName",
            text: t("OrderItemsTable.item"),
            sort: true,
        },
        {
            dataField: "price",
            text: t("OrderItemsTable.price"),
            sort: true,
            sortFunc: sortPrice,
            formatter: orderItemPriceFormatter,
        },
    ];

    return (
        <BootstrapTable
            bootstrap4
            keyField={"name"}
            data={orderItems}
            columns={columns}
        />
    );
};
