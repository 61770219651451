import { Button } from "@vseth/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    createGateway,
    selectPaymentMethods,
} from "../features/payment/paymentSlice";
import { PaymentMethod } from "../proto/vseth/sip/payment/payment_pb";

// GatewayButtons renders buttons for all payment methods of the current order
export const GatewayButtons: React.FC = () => {
    const paymentMethods = useSelector(selectPaymentMethods);
    return (
        <div>
            {paymentMethods.map((m) => (
                <GatewayButton method={m} key={m} />
            ))}
        </div>
    );
};

interface GatewayButtonProps {
    method: PaymentMethod;
}

const GatewayButton: React.FC<GatewayButtonProps> = ({ method }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const methodLabel: Record<PaymentMethod, string> = {
        [PaymentMethod.PAYREXX]: t("GatewayButtons.payrexx"),
        [PaymentMethod.GUTHABEN]: t("GatewayButtons.guthaben"),
        [PaymentMethod.DUMMY]: t("GatewayButtons.dummy"),
        [PaymentMethod.PAYMENT_METHOD_UNSPECIFIED]: t(
            "GatewayButtons.unspecified"
        ),
    };

    return (
        <Button
            className="m-2"
            onClick={(_) => dispatch(createGateway(method))}
        >
            {methodLabel[method]}
        </Button>
    );
};
