import React from "react";
import "./App.css";
import { BrowserRouter, Switch } from "react-router-dom";
import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";
import { VSETHContext } from "@vseth/components";
import { useSelector } from "react-redux";
import { Navbar } from "../components/Navbar";
import { selectIsAuthenticated } from "../features/auth/authSlice";
import { CustomAuthProvider } from "../components/CustomAuthProvider";
import { ProtectedRoute } from "@vseth/auth-components";
import OrderPage from "../pages/OrderPage";

/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
const App: React.FC = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const isAuthorized = () => isAuthenticated;

    return (
        <CustomAuthProvider>
            <BrowserRouter>
                <VSETHContext>
                    <div className="mobile-capable">
                        <Navbar />
                        <Switch>
                            <ProtectedRoute
                                path="/orders/:id"
                                component={OrderPage}
                                isAuthorized={isAuthorized}
                            />
                        </Switch>
                    </div>
                </VSETHContext>
            </BrowserRouter>
        </CustomAuthProvider>
    );
};

export default App;
