import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectTokens, setAuthState} from "../features/auth/authSlice";
import {AuthProvider, getAuthStateFromToken} from "@vseth/auth";

export const CustomAuthProvider: React.FC = props => {
  const tokens = useSelector(selectTokens);

  const dispatch = useDispatch();

  const initConfig = {
    flow: "implicit",
    checkLoginIframe: false,
    onLoad: undefined
  } as Keycloak.KeycloakInitOptions;
  return (
    <AuthProvider
      setAuthState={authState => {
        dispatch(setAuthState(authState))
      }}
      authState={getAuthStateFromToken(tokens)}
      LoadingComponent={<p>Loading</p>}
      isLoadingCheck={keycloak => false}
      initConfig={initConfig}
    >
      {props.children}
    </AuthProvider>
  );
};
