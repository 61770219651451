import React from "react";
import { ICONS, VSETHNavbar } from "@vseth/components";
import { Item } from "@vseth/components/dist/components/VSETHNav/VSETHNavbar";
import { LoginButton } from "@vseth/auth-components";
import i18n from "../locals/i18n";

const navbarconfig = [] as Item[];

/**
 * The Navbar component displays the top navigation bar of the application.
 */
export const Navbar = () => {
    return (
        <VSETHNavbar
            primaryActionItems={[
                {
                    title: "language",
                    icon: ICONS.LANGUAGE,
                    onClick: () => {
                        i18n.changeLanguage(
                            i18n.language === "de" ? "en" : "de"
                        );
                    },
                },
            ]}
            secondaryNavItems={navbarconfig}
            primarySlot={<LoginButton outline color="white" className="ml-3" />}
            mobileTopSlot={
                <LoginButton color="primary" className="m-2 ml-auto" />
            }
        />
    );
};
