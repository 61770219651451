import React from "react";
import { Spinner } from "@vseth/components";

interface LoadScreenProps {
    header?: string;
    body?: string;
}

export const LoadScreen: React.FC<LoadScreenProps> = ({ header, body }) => {
    return (
        <div
            className="d-flex flex-column justify-content-center text-center align-items-center"
            style={{ height: "80vh" }}
        >
            {header && <h1>{header}</h1>}
            {body && <p>{body}</p>}
            <Spinner style={{ alignItems: "center" }} color="primary" />
        </div>
    );
};

export default LoadScreen;
