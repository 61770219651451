import React from "react";
import { Button, Spinner } from "@vseth/components";
import { useTranslation } from "react-i18next";

interface RedirectScreenProps {
    explanation?: string;
    to: string;
}

export const RedirectScreen: React.FC<RedirectScreenProps> = ({
    explanation,
    to,
}) => {
    const { t } = useTranslation();

    // redirecting to "to"
    window.location.assign(to);

    return (
        <div
            className="d-flex flex-column justify-content-center text-center align-items-center"
            style={{ height: "80vh" }}
        >
            <h1>{t("RedirectScreen.header")}</h1>
            <p>{explanation || t("RedirectScreen.body")}</p>
            <Spinner style={{ alignItems: "center" }} color="primary" />
            <p style={{ marginTop: "1rem" }}>
                {t("RedirectScreen.pressButtonIfFail")}
            </p>
            <Button color="primary" href={to}>
                {t("RedirectScreen.redirectButton")}
            </Button>
        </div>
    );
};

export default RedirectScreen;
