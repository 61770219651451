/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for vseth.sip.payment
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var vseth_type_money_pb = require('../../../vseth/type/money_pb.js')
const proto = {};
proto.vseth = {};
proto.vseth.sip = {};
proto.vseth.sip.payment = require('./payment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.vseth.sip.payment.PaymentClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.vseth.sip.payment.PaymentPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.ListOrdersRequest,
 *   !proto.vseth.sip.payment.ListOrdersResponse>}
 */
const methodDescriptor_Payment_ListOrders = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.ListOrdersRequest,
  proto.vseth.sip.payment.ListOrdersResponse,
  /**
   * @param {!proto.vseth.sip.payment.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.ListOrdersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.ListOrdersRequest,
 *   !proto.vseth.sip.payment.ListOrdersResponse>}
 */
const methodInfo_Payment_ListOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.ListOrdersResponse,
  /**
   * @param {!proto.vseth.sip.payment.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.ListOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.ListOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.ListOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/ListOrders',
      request,
      metadata || {},
      methodDescriptor_Payment_ListOrders,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.ListOrdersResponse>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/ListOrders',
      request,
      metadata || {},
      methodDescriptor_Payment_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.CreateOrderRequest,
 *   !proto.vseth.sip.payment.Order>}
 */
const methodDescriptor_Payment_CreateOrder = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.CreateOrderRequest,
  proto.vseth.sip.payment.Order,
  /**
   * @param {!proto.vseth.sip.payment.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.Order.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.CreateOrderRequest,
 *   !proto.vseth.sip.payment.Order>}
 */
const methodInfo_Payment_CreateOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.Order,
  /**
   * @param {!proto.vseth.sip.payment.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.Order.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.Order)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.Order>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_Payment_CreateOrder,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.Order>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_Payment_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.GetOrderRequest,
 *   !proto.vseth.sip.payment.Order>}
 */
const methodDescriptor_Payment_GetOrder = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/GetOrder',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.GetOrderRequest,
  proto.vseth.sip.payment.Order,
  /**
   * @param {!proto.vseth.sip.payment.GetOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.Order.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.GetOrderRequest,
 *   !proto.vseth.sip.payment.Order>}
 */
const methodInfo_Payment_GetOrder = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.Order,
  /**
   * @param {!proto.vseth.sip.payment.GetOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.Order.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.Order)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.Order>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.getOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/GetOrder',
      request,
      metadata || {},
      methodDescriptor_Payment_GetOrder,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.Order>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.getOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/GetOrder',
      request,
      metadata || {},
      methodDescriptor_Payment_GetOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.RefundOrderItemsRequest,
 *   !proto.vseth.sip.payment.RefundOrderItemsResponse>}
 */
const methodDescriptor_Payment_RefundOrderItems = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/RefundOrderItems',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.RefundOrderItemsRequest,
  proto.vseth.sip.payment.RefundOrderItemsResponse,
  /**
   * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.RefundOrderItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.RefundOrderItemsRequest,
 *   !proto.vseth.sip.payment.RefundOrderItemsResponse>}
 */
const methodInfo_Payment_RefundOrderItems = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.RefundOrderItemsResponse,
  /**
   * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.RefundOrderItemsResponse.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.RefundOrderItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.RefundOrderItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.refundOrderItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/RefundOrderItems',
      request,
      metadata || {},
      methodDescriptor_Payment_RefundOrderItems,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.RefundOrderItemsResponse>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.refundOrderItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/RefundOrderItems',
      request,
      metadata || {},
      methodDescriptor_Payment_RefundOrderItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.ListPaymentGatewaysRequest,
 *   !proto.vseth.sip.payment.ListPaymentGatewaysResponse>}
 */
const methodDescriptor_Payment_ListPaymentGateways = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/ListPaymentGateways',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.ListPaymentGatewaysRequest,
  proto.vseth.sip.payment.ListPaymentGatewaysResponse,
  /**
   * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.ListPaymentGatewaysResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.ListPaymentGatewaysRequest,
 *   !proto.vseth.sip.payment.ListPaymentGatewaysResponse>}
 */
const methodInfo_Payment_ListPaymentGateways = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.ListPaymentGatewaysResponse,
  /**
   * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.ListPaymentGatewaysResponse.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.ListPaymentGatewaysResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.ListPaymentGatewaysResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.listPaymentGateways =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/ListPaymentGateways',
      request,
      metadata || {},
      methodDescriptor_Payment_ListPaymentGateways,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.ListPaymentGatewaysResponse>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.listPaymentGateways =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/ListPaymentGateways',
      request,
      metadata || {},
      methodDescriptor_Payment_ListPaymentGateways);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.CreatePaymentGatewayRequest,
 *   !proto.vseth.sip.payment.PaymentGateway>}
 */
const methodDescriptor_Payment_CreatePaymentGateway = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/CreatePaymentGateway',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.CreatePaymentGatewayRequest,
  proto.vseth.sip.payment.PaymentGateway,
  /**
   * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.PaymentGateway.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.CreatePaymentGatewayRequest,
 *   !proto.vseth.sip.payment.PaymentGateway>}
 */
const methodInfo_Payment_CreatePaymentGateway = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.PaymentGateway,
  /**
   * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.PaymentGateway.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.PaymentGateway)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.PaymentGateway>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.createPaymentGateway =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/CreatePaymentGateway',
      request,
      metadata || {},
      methodDescriptor_Payment_CreatePaymentGateway,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.PaymentGateway>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.createPaymentGateway =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/CreatePaymentGateway',
      request,
      metadata || {},
      methodDescriptor_Payment_CreatePaymentGateway);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vseth.sip.payment.GetPaymentGatewayRequest,
 *   !proto.vseth.sip.payment.PaymentGateway>}
 */
const methodDescriptor_Payment_GetPaymentGateway = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/GetPaymentGateway',
  grpc.web.MethodType.UNARY,
  proto.vseth.sip.payment.GetPaymentGatewayRequest,
  proto.vseth.sip.payment.PaymentGateway,
  /**
   * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.PaymentGateway.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vseth.sip.payment.GetPaymentGatewayRequest,
 *   !proto.vseth.sip.payment.PaymentGateway>}
 */
const methodInfo_Payment_GetPaymentGateway = new grpc.web.AbstractClientBase.MethodInfo(
  proto.vseth.sip.payment.PaymentGateway,
  /**
   * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vseth.sip.payment.PaymentGateway.deserializeBinary
);


/**
 * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vseth.sip.payment.PaymentGateway)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vseth.sip.payment.PaymentGateway>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.getPaymentGateway =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/GetPaymentGateway',
      request,
      metadata || {},
      methodDescriptor_Payment_GetPaymentGateway,
      callback);
};


/**
 * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vseth.sip.payment.PaymentGateway>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.getPaymentGateway =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/GetPaymentGateway',
      request,
      metadata || {},
      methodDescriptor_Payment_GetPaymentGateway);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Payment_TriggerStateUpdate = new grpc.web.MethodDescriptor(
  '/vseth.sip.payment.Payment/TriggerStateUpdate',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Payment_TriggerStateUpdate = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vseth.sip.payment.PaymentClient.prototype.triggerStateUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vseth.sip.payment.Payment/TriggerStateUpdate',
      request,
      metadata || {},
      methodDescriptor_Payment_TriggerStateUpdate,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.vseth.sip.payment.PaymentPromiseClient.prototype.triggerStateUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vseth.sip.payment.Payment/TriggerStateUpdate',
      request,
      metadata || {},
      methodDescriptor_Payment_TriggerStateUpdate);
};


module.exports = proto.vseth.sip.payment;

