import React, { useEffect } from "react";
import { Container } from "@vseth/components";
import { useTranslation } from "react-i18next";
import {
    Order,
    fetchOrder,
    selectOrder,
    selectError,
    selectIsLoading,
    selectPaymentGatewayRedirect,
    OrderStatus,
    orderNamePrefix,
} from "../features/payment/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadScreen from "../components/LoadScreen";
import ErrorScreen from "../components/ErrorScreen";
import { selectIsAuthenticated } from "../features/auth/authSlice";
import { Checkout } from "../components/Checkout";
import RedirectScreen from "../components/RedirectScreen";

interface Props {
    match: {
        params: {
            id: string;
        };
    };
}

// OrderPage renders a order in all of its possible state together with buttons to pay it if applicable
const OrderPage: React.FC<Props> = ({ match }) => {
    const order = useSelector(selectOrder);
    const redirectURL = useSelector(selectPaymentGatewayRedirect);
    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        dispatch(fetchOrder(orderNamePrefix + match.params.id));
    }, [dispatch, match.params.id, isAuthenticated]);

    // Redirect when gateway url is ready
    if (redirectURL) {
        return <RedirectScreen to={redirectURL} />;
    }

    if (isLoading) {
        return (
            <LoadScreen
                header={t("OrderPage.wait")}
                body={t("OrderPage.orderPreparing")}
            />
        );
    }
    if (error || !order) {
        return <ErrorScreen />;
    }

    switch (order.status) {
        case OrderStatus.OPEN:
            return <OrderOpen order={order} />;
        case OrderStatus.PAID:
            return (
                <RedirectScreen
                    to={order.callbackUrl}
                    explanation={t("OrderPage.paidExplanation", {
                        reference: order.number,
                    })}
                />
            );
        case OrderStatus.EXPIRED:
            return <OrderExpired order={order} />;
        default:
            return <ErrorScreen />;
    }
};

interface PropsWithOrder {
    order: Order;
}

const OrderOpen: React.FC<PropsWithOrder> = ({ order }) => {
    return (
        <Container>
            <section className="mb-5">
                <h1 className="mb-3">Checkout</h1>
                <Checkout order={order} />
            </section>
        </Container>
    );
};

const OrderExpired: React.FC<PropsWithOrder> = ({ order }) => {
    const { t } = useTranslation();

    return (
        <div
            className="d-flex flex-column justify-content-center text-center"
            style={{ height: "80vh" }}
        >
            <h1>{t("OrderPage.expired")}</h1>
            <p>{t("OrderPage.orderExpired", { reference: order.number })}</p>
        </div>
    );
};

export default OrderPage;
